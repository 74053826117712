import React from "react"

import Box from "../../../components/box"
import Breadcrumb from "../../../components/breadcrumb"
import Button from "../../../components/button"
import Constrain from "../../../components/constrain"
import Grid from "../../../components/grid"
import EventList from "../../../components/event-list"
import FuehrungenPreise from "../../../content/fuehrungen/preise"
import Heading from "../../../components/heading"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"

const SonderausstellungHoelderlinsOrte = () => {
  return (
    <Layout backdrop="orte">
      <Seo
        title="Hölderlins Orte - Fotografien von Barbara Klemm"
        description="Nach Stationen in Nürtingen, Jena, Bad Homburg v.d.H., Berlin, Lauffen a.N. und Aix en Provence macht die von der Universitätsstadt Tübingen zum Hölderlin-Jubiläumsjahr 2020 konzipierte Wanderausstellung noch ein letztes Mal am Ort ihrer Entstehung Station: Friedrich Hölderlins Verse und Landschaftsbeschreibungen treffen darin auf Bilder der Fotografin Barbara Klemm."
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Ausstellungen",
              link: "/ausstellungen",
            },
            {
              title: "Hölderlins Orte",
              link: "/ausstellungen/sonderausstellungen/hoelderlins-orte",
            },
          ]}
        />
        <Stack space={[12, 24]}>
          <Stack>
            <PageTitle>
              Hölderlins Orte - Fotografien von Barbara Klemm
            </PageTitle>
            <Constrain>
              <Stack>
                <Heading level={4} as="p" color="primary">
                  6. März bis 15. Juni 2022
                </Heading>
                <Paragraph dropcap={true}>
                  Nach Stationen in Nürtingen, Jena, Bad Homburg v.d.H., Berlin,
                  Lauffen a.N. und Aix en Provence macht die von der
                  Universitätsstadt Tübingen zum Hölderlin-Jubiläumsjahr 2020
                  konzipierte Wanderausstellung noch ein letztes Mal am Ort
                  ihrer Entstehung Station: Friedrich Hölderlins Verse und
                  Landschaftsbeschreibungen treffen darin auf Bilder der
                  Fotografin Barbara Klemm. Eigens für die Ausstellung folgte
                  die ehemalige FAZ-Fotografin mit der Kamera den
                  Blickrichtungen aus Hölderlins Gedichten und bereiste die
                  Orte, die sich in sein Leben und Werk eingeprägt haben.
                </Paragraph>
              </Stack>
            </Constrain>
          </Stack>

          <Stack space={[12, 20]}>
            <Constrain>
              <Heading as="h2" level={3}>
                Führungen durch die Ausstellung
              </Heading>
            </Constrain>

            <Constrain>
              <Stack space={12}>
                <Stack space={6}>
                  <Heading as="h3" level={5}>
                    Öffentliche Führungen
                  </Heading>
                  <Paragraph>
                    Jeden 2. und 4. Sonntag bieten wir eine 1-stündige
                    öffentliche Führung durch die Sonderausstellung an.
                  </Paragraph>
                  <FuehrungenPreise />
                </Stack>
              </Stack>
            </Constrain>

            <Stack space={6}>
              <Constrain>
                <Heading as="h4" level={6}>
                  Nächste Termine
                </Heading>
              </Constrain>

              <EventList
                density="compact"
                headingElement="p"
                showPastEvents={true}
                category="fuehrung-hoelderlins-orte"
              />
            </Stack>

            <Constrain>
              <Stack space={6}>
                <Heading as="h3" level={5}>
                  Gruppenführungen
                </Heading>
                <Paragraph>
                  Darüber hinaus können Gruppen auch individuell einen Rundgang
                  durch die Sonderausstellung buchen.
                </Paragraph>

                <Grid columns={[1, 2]} space={1}>
                  <Box p={3} bg="muted">
                    <Stack space={1}>
                      <Text color="whisper" sans size={2}>
                        Dauer
                      </Text>
                      <Paragraph>60 Minuten</Paragraph>
                    </Stack>
                  </Box>
                  <Box p={3} bg="muted">
                    <Stack space={1}>
                      <Text color="whisper" sans size={2}>
                        Gruppenpreis
                      </Text>
                      <Paragraph>60 €</Paragraph>
                    </Stack>
                  </Box>
                </Grid>
                <Paragraph>
                  Schreiben Sie uns dafür gerne eine Mail an{" "}
                  <TextLink href="mailto:fuehrungen-hoelderlinturm@tuebingen.de?subject=Gruppenführung%0D%0ASonderausstellung">
                    fuehrungen-hoelderlinturm@tuebingen.de
                  </TextLink>
                </Paragraph>
              </Stack>
            </Constrain>
          </Stack>

          <Constrain>
            <Box bg="muted" p={[6, 12]}>
              <Stack>
                <Stack space={6}>
                  <Heading as="h2" level={4}>
                    Digitale Sonderausstellung
                  </Heading>
                  <Paragraph>
                    Sie sind neugierig auf die Ausstellung oder möchten das
                    Gesehene noch einmal vertiefen? In der digitalen Ausstellung
                    haben wir eine kleine Auswahl aus Barbara Klemm Fotografien
                    zusammengestellt und mit spannenden Hintergrundinformationen
                    zu Friedrich Hölderlin und seinen Orten angereichert.
                  </Paragraph>
                </Stack>
                <Button to="/sonderausstellungen/hoelderlins-orte">
                  Zur Digitalen Sonderausstellung
                </Button>
              </Stack>
            </Box>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default SonderausstellungHoelderlinsOrte
